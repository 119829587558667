import React from 'react';
import {
    Datagrid,
    TextField,
    List,
    EditButton,
    DeleteButton
} from 'react-admin';

export default (props) => (
    <List
        {...props}
        // filters={<UserFilter />}
        sort={{ field: 'code', order: 'ASC' }}
        perPage={25}
        bulkActionButtons={false}
        title="Liste des formats"
    >
        <Datagrid>
            <TextField source="code" label="Code"/>
            <TextField source="label" label="Libellé"/>
            <EditButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
);