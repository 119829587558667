import React from 'react';
import {
    SimpleForm,
    TextInput,
    Edit
} from 'react-admin';

export default (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="code" label="Code"/>
            <TextInput source="label" label="Libellé"/>
            <TextInput multiline source="info" label="Informations complémentaires"/>
        </SimpleForm>
    </Edit>
);