import React from 'react';
import { Admin } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import { ResourceWithPermissions } from 'ra-auth-acl';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import frenchMessages from 'ra-language-french';
import { fetchUtils } from 'ra-core';

import SpaceList from './spaces/List';
import SpaceEdit from './spaces/Edit';
import SpaceCreate from './spaces/Create';
import FormatList from './formats/List';
import FormatEdit from './formats/Edit';
import FormatCreate from './formats/Create';
import ProjectList from './projects/List';
import ProjectEdit from './projects/Edit';
import ProjectCreate from './projects/Create';

import authProvider from './authProvider';

require('dotenv').config();

const i18nProvider = polyglotI18nProvider(() => frenchMessages, 'fr');

const fetchJson = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    console.log(token)
    options.headers.set('Authorization', token);
    options.credentials =
        process.env.REACT_APP_HTTP_CREDENTIALS || 'same-origin';
    return fetchUtils.fetchJson(url, options);
};

const App = () => (
    <Admin 
        authProvider={authProvider}
        dataProvider={simpleRestProvider(`${process.env.REACT_APP_API_URL}/api`, fetchJson)}
        i18nProvider={i18nProvider}
    >
        {(permissions) =>
            [
                <ResourceWithPermissions permissions={permissions} name="space" options={{ label: 'Espaces'}} list={SpaceList} edit={SpaceEdit} create={SpaceCreate}/>,
                <ResourceWithPermissions permissions={permissions} name="format" options={{ label: 'Formats'}} list={FormatList} edit={FormatEdit} create={FormatCreate}/>,
                <ResourceWithPermissions permissions={permissions} name="project" options={{ label: 'Projets'}} list={ProjectList} edit={ProjectEdit} create={ProjectCreate}/>
            ]
        }
    </Admin>
);

export default App;