import React from 'react';
import {
    SimpleForm,
    TextInput,
    Create
} from 'react-admin';

export default (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="code" label="Code"/>
            <TextInput source="label" label="Libellé"/>
            <TextInput multiline source="info" label="Informations complémentaires"/>
        </SimpleForm>
    </Create>
);